import React, { FC } from 'react';

import { graphql } from 'gatsby';
import { IGatsbyConnection } from 'gatsby/dist/gatsby/src/schema/type-definitions';
import { Header } from 'components/Header';
import { Footer } from 'components/Footer';
import { Seo } from 'components/Seo';
import { CookiesConsent } from 'components/CookiesConsent';
import { IGlobal, ILocale, ILocalization, IPage, IPageWithLocalizations, IParent, IPublicLocales } from 'interfaces';
import 'src/styles/main.scss';

import classes from './NotOldEnoughLayout.module.scss';

export const query = graphql`
    query NotOldEnoughPage($locale: String!, $strapiId: Int!) {
        global: strapiGlobal(locale: { eq: $locale }) {
            siteName
            locale
            logo {
                url
                width
                height
                mime
            }
            footerContent {
                footerNavigation {
                    id
                    url
                    text
                    newTab
                    type
                    column
                }
                footerBottom {
                    id
                    url
                    text
                    newTab
                    type
                }
                socialTitle
                socialLinks {
                    id
                    type
                    url
                }
                licensesLinks {
                    id
                    url
                    text
                    icon {
                        url
                        width
                        height
                        mime
                    }
                }
                copyright
            }
            defaultSeo {
                metaTitle
                metaDescription
            }
            favicon {
                width
                ext
                hash
                height
                mime
                name
                size
                provider
                url
            }
            navigationLinks {
                id
                url
                newTab
                text
                type
            }
            cookiesConsent {
                consentMessage
                acceptButtonText
                declineButtonText
            }
            recaptchaV2Key
            latestNewsHeader
            notOldEnoughPage {
                id
                heading
            }
        }
        pages: allStrapiPage(
            filter: { displayInNavigation: { eq: true }, locale: { eq: $locale } }
            sort: { fields: [sort], order: ASC }
        ) {
            edges {
                node {
                    slug
                    title
                    locale
                }
            }
        }
        parents: allStrapiParent(filter: { locale: { eq: $locale } }) {
            edges {
                node {
                    id
                    sort
                    name
                    pages {
                        slug
                        title
                        locale
                    }
                }
            }
        }
        locale: strapiLocale(code: { eq: $locale }) {
            id
            name
            code
        }
        pageLocalization: strapiGlobal(strapiId: { eq: $strapiId }) {
            localizations {
                id
                locale
            }
            locale
        }
        pagesWithLocalization: allStrapiPage(filter: { locale: { ne: $locale } }) {
            nodes {
                strapiId
                slug
            }
        }
        locales: allStrapiLocale {
            edges {
                node {
                    name
                    code
                    isDefault
                }
            }
        }
        publicLocales: allStrapiGlobal {
            nodes {
                publishLocale
                locale
            }
        }
    }
`;

interface IProps {
    data: {
        global: IGlobal;
        pages: IGatsbyConnection<IPage>;
        parents: IGatsbyConnection<IParent>;
        locale: ILocale;
        locales: IGatsbyConnection<ILocale>;
        pageLocalization: IPageWithLocalizations | null;
        pagesWithLocalization: IGatsbyConnection<ILocalization> | null;
        publicLocales: IGatsbyConnection<IPublicLocales>;
    };
}

const NotOldEnoughPage: FC<IProps> = ({ data }) => {
    const page = data.global.notOldEnoughPage;

    return (
        <div className={classes.container}>
            <Seo global={data.global} />

            <Header
                parents={data.parents}
                global={data.global}
                pages={data.pages}
                locale={data.locale}
                locales={data.locales}
                localization={data.pageLocalization}
                pagesWithLocalization={data.pagesWithLocalization}
                publicLocales={data.publicLocales}
            />
            <main className={classes.main}>
                <h2 className={classes.main__heading}>{page?.heading}</h2>
            </main>
            <Footer global={data.global} pages={data.pages} locale={data.locale} />
            <CookiesConsent data={data.global.cookiesConsent} />
        </div>
    );
};

export default NotOldEnoughPage;
